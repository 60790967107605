
nav .pagination {
    justify-content: center;
    a {
    	text-decoration: none;
    }
}

.ooc-sidebar {
	a {
    	text-decoration: none;	
    }
	nav {
	    margin-bottom: 1em;
	}
    .nav-link {
	    display: block;
	    padding: 0.2em 0;
	}
}

nav.oocnav {
	margin: 1em auto;
	text-align: center;
}

@include media-breakpoint-down(md) {
}
