.comment-form-container {
    padding: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
}

.comments-box .comment-input,
.comments-box .form-group *,
.comments-box .email-input,
.comments-box .name-input {
    width: 100%;
}

.comments-box .form-control {
    margin-bottom: 10px;
}


/* Comments List */

.comments-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

/* Comment Item */

.comment-single .comment-single {
    padding-left: 40px;
}

.comment-single .comment-container {
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
}

.comments-list li .comment-text,
.comments-list li .comment-image {
    display: block;
}

.comments-list li .comment-text {
    padding-left: 40px;
}

.comments-list .comment-form {
    display: none;
}

.comment-image {
    width: 30px;
    margin-right: 10px;
    height: 30px;
    float: left;
}

.comment-image img {
    width: 100%;
}

.comment-text p {
    margin: 0;
}

.comment-heading {
    color: #aaa;
    font-size: 12px;
    margin-bottom: 5px;
    display: block;
}

.comment-heading .title {
    font-weight: 700;
    text-decoration: none;
    color: #288ce4;
    font-size: 13px;
}

.comment-heading .meta {
    margin-left: 5px;
    font-weight: 500;
    font-size: 12px;
    color: #a5b2b9;
}

/* Comment Item - Reply Form */

.comment-single .comment-form {
    margin: 20px 0 0 0;
    padding-bottom: 0;
    border-bottom: 1px solid #ddd;
    padding: 10px;
    margin: 0;
}

/* Comment Item - Meta Buttons */

.meta-buttons {
    margin-top: 10px;
}

.meta-buttons a {
    font-weight: bold;
    font-size: 11px;
    padding: 0 15px 0 0;
    color: #288ce4;
}

.meta-buttons .comment-votes {
    display: inline-block;
    margin-right: 15px;
}

.meta-buttons .comment-votes a {
    padding: 0 0 0 2px;
}

.meta-buttons .comment-votes .count {
    color: #a5b2b9;
    font-weight: 500;
    font-size: 13px;
    margin-right: 2px;
}

.meta-buttons .static-label {
    font-weight: bold;
    font-size: 11px;
    color: #a5b2b9;
}


/* Comment Item - Share/Options Dropdown */

.comment-heading .dropdown {
    float: right;
    margin-left: 20px;
}

.comment-heading .dropdown:hover .dropdown-menu {
    display: block;
}

.comment-heading .dropdown-toggle {
    font-weight: bold;
    font-size: 11px;
    color: #288ce4;
}

.comment-heading .dropdown-menu {
    right: 0;
    left: auto;
    min-width: 60px;
}

.comment-heading .dropdown-menu > li > a {
    font-weight: bold;
    font-size: 11px;
    color: #288ce4;
    padding: 5px 10px;
}

.comment-heading .dropdown-menu > li > span.comment-flag .glyphicon,
.comment-heading .dropdown-menu > li > a .fa,
.comment-heading .dropdown-menu > li > a .glyphicon {
    padding-right: 5px;
    width: 15px;
    text-align: center;
}

.comment-heading .dropdown-menu > li > span.comment-flag {
    display: block;
    white-space: nowrap;
    color: #aaa;
    font-weight: bold;
    font-size: 11px;
    padding: 5px 10px;
}