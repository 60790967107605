header {
	border-top: 10px solid $orange;
}

.postmetadata {
    text-style: oblique;
    font-size: 80%;
}

dt {
    font: 500 1.2em/2.2em "Ubuntu", sans-serif;
}
dd {
    font: 400 1em/2.2em "Lora", Georgia, Cambria, 'Times New Roman', Times, serif;
}

/* All levels of nav */
