$gray: #444444;
$orange: #ffad00;
$blue: #29b6f6;

$brand-primary: $orange;
$brand-info: $blue;

$orange-light: #ffdf4b;
$orange-dark: #c67e00;
$blue-light: #73e8ff;
$blue-dark: #0086c3;

$link-color: $blue-dark; 
$font-family-sans-serif: "Lora", "Helvetica Neue", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
$headings-font-family: "Ubuntu", "Helvetica Neue", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;

$line-height-base: 1.66;