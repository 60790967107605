a {
	text-decoration: underline;
}
a:hover {
	text-decoration: none;
    color: $blue;
}

h1 {
    text-transform: uppercase;
}

h2:not(:first-of-type),
h3:not(:first-of-type) {
	margin-top:1.5rem;
}

h1 a, 
h2 a, 
h3 a, 
h4 a, 
h5 a, 
h6 a, 
.nav li a {
	color: $gray;
    text-decoration: none;
    border-bottom: 0;
}

header {
	&.header span {
	    display: none;
	}

	a {
		border-bottom: none;
	}
}

footer, section {
    padding-top: 3em;
    padding-bottom: 3em;
}

article {
	margin-bottom: 3em;
}



.orangedark {color: $orange-dark;}
.orangelight {color: $orange-light;}
.bluedark {color: $blue-dark;}
.bluelight {color: $blue-light;}

.hljs {
	padding: 1.5em !important;
	border-radius: 0.25rem;
}

